import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */

import { RestaurantStateTypes } from "../Redux/RestaurantRedux";

/* ------------- Sagas ------------- */

import RestaurantApi from "../Services/restaurantApi";

import { setRestaurantDetails } from "./restaurantSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

const restaurantApi = RestaurantApi.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeLatest(
      RestaurantStateTypes.SET_RESTAURANT_DETAILS,
      setRestaurantDetails,
      restaurantApi
    ),
  ]);
}
