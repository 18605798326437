import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Input,
  InputLabel,
  TextField,
  ListItemText,
  ButtonGroup,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import moment from "moment";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import Loading from "../../components/Loading/Loading";
import background3 from "../../assets/images/background3.png";
import giftCards from "../../assets/images/giftCards.jpg";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import "moment/locale/fr";
import MaskedInput from "react-text-mask";
import CreditCard from "../../components/CreditCard/CreditCard";
import giftCardsApi from "../../Services/giftCardsApi";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import CloverPayment from "../../components/CloverPayment/CloverPayment";
import PoyntPayment from "../../components/PoyntPayment/PoyntPayment";
import encrypt from '../../Services/crypt.service'

const classes = (theme) => {
  return {
    cardTitle: {
      backgroundColor: theme.palette.secondary.main,
      padding: 16,
      textAlign: "center",
      color: "white",
      fontSize: 16,
      fontWeight: 600,
    },
    link: {
      color: theme.palette.secondary.main,
    },
  };
};

function PhoneNumberMasking(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

class PurchaseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      businessName: "",
      recipientName: "",
      recipientNameError: false,
      recipientEmail: "",
      recipientEmailError: false,
      senderEmail: "",
      senderEmailError: false,
      recipientPhone: "",
      recipientPhoneError: false,
      name: "",
      nameError: false,
      message: "",
      messageError: false,
      amount:
        this.props.restaurantDetails.businessName === "Do My Laundry"
          ? ""
          : 100,
      amountError: false,
      googleCaptchaToken: null,
      captchaError: false,
      poyntProcessing: false,
      errorMessage: "",
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    const restaurantDomain = this.props.match?.params?.restaurant;
    const locationDomain = this.props.match?.params?.location;
    if (
      !this.props.restaurantDetails.businessName ||
      this.props.restaurantDetails.businessDomain !== restaurantDomain ||
      this.props.restaurantDetails.locationDomain !== locationDomain
    ) {
      this.props.history.push("/" + restaurantDomain + "/" + locationDomain);
    }
    const navigationEntries = performance.getEntriesByType("navigation");

    // Check if the page is being refreshed
    if (navigationEntries.length && navigationEntries[0].type === "reload") {
      // Redirect to the home screen
      window.location.replace("/"+ restaurantDomain + "/" + locationDomain); // Replace '/' with the path to your home screen
    }
    window.onbeforeunload = function () {
      return "Are you sure to leave this page?";
    };
  }

  setThemeColor = () => {
    if (
      this.props.restaurantDetails.themeColor &&
      this.props.restaurantDetails.themeColor !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(this.props.restaurantDetails.themeColor);
    }
  };

  handleAmountChange = (event) => {
    console.log("restaurantDomain", this.props.match?.params?.restaurant);
    console.log("locationDomain", this.props.match?.params?.location);
    const enteredAmount = event.target.value;
    const isAmountValid = /^[0-9]*$/.test(enteredAmount); // Check if input is a valid number
    const isZeroOrEmpty = enteredAmount === "" || parseInt(enteredAmount) === 0;
    const maxAmount = this.props.restaurantDetails.perCardAmount / 100;
    const balanceStoreAmount =
      this.props.restaurantDetails.balanceStoreLimit / 100;
    const is_store_limit = this.props.restaurantDetails.isStoreLimitEnable;
    const formattedMaxAmount = `$${maxAmount}`;
    const formattedStoreAmount =
      balanceStoreAmount < 0 ? "$ 0" : `$${balanceStoreAmount}`;
    console.log("isAmountValid", isAmountValid);
    console.log("isZeroOrEmpty", isZeroOrEmpty);
    console.log("is_store_limit", is_store_limit);
    console.log("formattedStoreAmount", formattedStoreAmount);

    let amountError = false;
    let errorMessage = "";

    if (
      is_store_limit &&
      (!isAmountValid || parseInt(enteredAmount) > maxAmount)
    ) {
      amountError = true;
      errorMessage = isAmountValid
        ? this.props.t(
            `The maximum amount percard limit is ${formattedMaxAmount} only!`
          )
        : this.props.t("Please enter a amount!");
    } else if (
      is_store_limit &&
      (!isAmountValid || parseInt(enteredAmount) > balanceStoreAmount)
    ) {
      amountError = true;
      errorMessage = isAmountValid
        ? this.props.t(
            `The remaining balance in the store's is only ${formattedStoreAmount} for the day `
          )
        : this.props.t("Please enter a amount!");
    } else if (!isAmountValid || isZeroOrEmpty) {
      amountError = true;
      errorMessage = isAmountValid
        ? this.props.t("Please enter a amount! ")
        : this.props.t("Please enter a amount!");
    } else if (!isAmountValid || enteredAmount > 10000) {
      amountError = true;
      errorMessage = isAmountValid
        ? this.props.t(
            "Please enter a card balance less than or equal to $10000! "
          )
        : this.props.t(
            "Please enter a card balance less than or equal to $10000!"
          );
    }

    this.setState({
      amount: enteredAmount,
      amountError,
      errorMessage,
    });
  };

  changeLanguage = (lan = false) => {
    if (
      !this.props.restaurantDetails.loading &&
      this.props.restaurantDetails.businessName &&
      !this.props.restaurantDetails.currentLanguage
    ) {
      this.props.i18n.changeLanguage(
        this.props.restaurantDetails.preferredLanguage
      );
      this.props.dispatch(
        RestaurantActions.changeLanguage(
          this.props.restaurantDetails.preferredLanguage
        )
      );
    } else {
      if (lan && lan !== this.props.restaurantDetails.currentLanguage) {
        this.props.i18n.changeLanguage(lan);
        this.props.dispatch(RestaurantActions.changeLanguage(lan));
      }
    }
  };

  goToPurchaseStep = () => {
    if (!/^([0-9]*[.])?[0-9]+$/.test(this.state.amount)) {
      this.setState({ amountError: true });
    } else if (this.state.amount == 0 || this.state.amount > 10000) {
      this.setState({ amountError: true });
    } else {
      this.setState({ amountError: false, step: 3 });
    }
  };

  purchase = (request) => {
    const requestObj = {
      recipient_name: this.state.recipientName,
      recipient_email: this.state.recipientEmail,
      sender_email: this.state.senderEmail,
      recipient_phone_number: this.state.recipientPhone,
      sender_name: this.state.name,
      message: this.state.message,
      amount: this.state.amount * 100,
      delivery_date: null,
      sender_phone_number: null,
      should_copy_sender: true,
      card_issued_from: "online",
      payment_method: "converge",
      converge: {
        name: request.nameOnCreditCard,
        card_number:encrypt(request.creditCardNumber),
        expiry_month: encrypt(request.expiryMonth),
        expiry_year: encrypt(request.expiryYear),
        cvv: encrypt(request.creditCardCvv),
        zip_code: request.creditCardZipCode,
      },
      coupon_code: request.couponCode,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });

    apiService
      .purchaseGiftCard(
        this.props.restaurantDetails.businessCode,
        this.props.restaurantDetails.locationCode,
        requestObj
      )
      .then((response) => {
        // console.log("purchaseGiftCard", response);
        if (response.ok && response.data.gift_card) {
          toast.success(response.data.message);
          // toast.success(this.props.t("Payment Successful!"));
          this.setState({
            step: 4,
            loading: false,
            businessName: "",
            recipientName: "",
            recipientNameError: false,
            recipientEmail: "",
            recipientEmailError: false,
            senderEmail: "",
            senderEmailError: false,
            recipientPhone: "",
            recipientPhoneError: false,
            name: "",
            nameError: false,
            message: "",
            messageError: false,
            amount: 100,
            amountError: false,
          });
        } else {
          this.setState({
            loading: false,
          });
          if (
            response.data.status == "Failure" &&
            response.data.status_message
          ) {
            toast.error(response.data.status_message);
          } else {
            toast.error(response.data.message);
            // toast.error(this.props.t("Payment Failed! Please try again!"));
          }
        }
      });
  };

  purchaseWithPoynt = (token) => {
    const requestObj = {
      business_id: this.props.restaurantDetails.businessId,
      location_id: this.props.restaurantDetails.locationId,
      recipient_name: this.state.recipientName,
      recipient_email: this.state.recipientEmail,
      sender_email: this.state.senderEmail,
      recipient_phone: this.state.recipientPhone,
      sender_name: this.state.name,
      message: this.state.message,
      poynt_response: token,
      amount: this.state.amount * 100,
      coupon_code: this.state.appliedCoupon,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true, poyntProcessing: true });

    apiService.purchaseGiftCardPoynt(requestObj).then((response) => {
      if (response.ok && response.data.gift_card) {
        toast.success(this.props.t("Payment Successful!"));
        this.setState({
          step: 4,
          loading: false,
          businessName: "",
          recipientName: "",
          recipientNameError: false,
          recipientEmail: "",
          recipientEmailError: false,
          senderEmail: "",
          senderEmailError: false,
          recipientPhone: "",
          recipientPhoneError: false,
          name: "",
          nameError: false,
          message: "",
          messageError: false,
          amount: 100,
          amountError: false,
          discountAmount: "",
          appliedCoupon: "",
        });
      } else {
        this.setState({
          loading: false,
          poyntProcessing: false,
        });
        if (response.data.status == "Failure" && response.data.status_message) {
          toast.error(response.data.status_message);
        } else {
          toast.error(this.props.t("Payment Failed! Please try again!"));
        }
      }
    });
  };
  purhcaseWithClover = (token, couponCode) => {
    const requestObj = {
      recipient_name: this.state.recipientName,
      recipient_email: this.state.recipientEmail,
      sender_email: this.state.senderEmail,
      recipient_phone_number: this.state.recipientPhone,
      sender_name: this.state.name,
      message: this.state.message,
      amount: this.state.amount * 100,
      delivery_date: null,
      sender_phone_number: null,
      should_copy_sender: true,
      payment_method: "clover",
      clover: {
        source: token,
      },
      coupon_code: couponCode,
    };

    let apiService = giftCardsApi.create();
    this.setState({ loading: true });

    apiService
      .purchaseGiftCard(
        this.props.restaurantDetails.businessCode,
        this.props.restaurantDetails.locationCode,
        requestObj
      )
      .then((response) => {
        // console.log('purchaseGiftCard clover', response);
        if (response.ok && response.data.gift_card) {
          // toast.success(response.data.message);
          toast.success(this.props.t("Payment Successful!"));
          this.setState({
            step: 4,
            loading: false,
            businessName: "",
            recipientName: "",
            recipientNameError: false,
            recipientEmail: "",
            recipientEmailError: false,
            recipientPhone: "",
            recipientPhoneError: false,
            name: "",
            nameError: false,
            message: "",
            messageError: false,
            amount: 100,
            amountError: false,
          });
        } else {
          this.setState({
            loading: false,
          });
          if (
            response.data.status == "Failure" &&
            response.data.status_message
          ) {
            toast.error(response.data.status_message);
          } else {
            toast.error(this.props.t("Payment Failed! Please try again!"));
          }
        }
      });
  };

  goToHome = () => {
    this.setState({
      loading: false,
      step: 4,
      businessName: "",
      recipientName: "",
      recipientNameError: false,
      recipientEmail: "",
      recipientEmailError: false,
      senderEmail: "",
      senderEmailError: false,
      recipientPhone: "",
      recipientPhoneError: false,
      name: "",
      nameError: false,
      message: "",
      messageError: false,
      amount: 100,
      amountError: false,
      googleCaptchaToken: null,
      captchaError: false,
    });

    this.props.history.push(
      "/" +
        this.props.restaurantDetails.businessDomain +
        "/" +
        this.props.restaurantDetails.locationDomain
    );
  };

  goToAmountStep = () => {
    //Check if all the values are correct
    let recipientNameError = false;
    let recipientEmailError = false;
    let senderEmailError = false;
    let recipientPhoneError = false;
    let nameError = false;
    let messageError = false;

    // Name checking
    if (!/^[a-zA-Z\s]+$/.test(this.state.recipientName)) {
      recipientNameError = true;
    }

    // Email Checking
    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        this.state.recipientEmail
      )
    ) {
      recipientEmailError = true;
    }

    // Email Checking
    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        this.state.senderEmail
      )
    ) {
      senderEmailError = true;
    }

    //Phone Number Checking
    if (
      this.state.recipientPhone?.length > 0 &&
      this.state.recipientPhone.length != 14
    ) {
      recipientPhoneError = true;
    } else {
      recipientPhoneError = false;
    }

    // Name checking
    if (!/^[a-zA-Z\s]+$/.test(this.state.name)) {
      nameError = true;
    } else {
      nameError = false;
    }

    // Message checking
    if (this.state.message?.length > 0) {
      if (!/^([a-zA-Z0-9 _,.!\(\)\'\";:-]+)$/.test(this.state.message)) {
        messageError = true;
      } else {
        messageError = false;
      }
    }

    if (
      !recipientNameError &&
      !recipientEmailError &&
      !senderEmailError &&
      !recipientPhoneError &&
      !nameError &&
      !messageError
    ) {
      this.recaptchaRef.current.executeAsync().then((value) => {
        this.setState({
          recipientNameError,
          recipientEmailError,
          senderEmailError,
          recipientPhoneError,
          nameError,
          messageError,
          step: 2,
        });
      });
    } else {
      this.setState({
        recipientNameError,
        recipientEmailError,
        senderEmailError,
        recipientPhoneError,
        nameError,
        messageError,
      });
    }
  };

  onChange = (event) => {
    this.setState({
      googleCaptchaToken: event,
    });
  };

  handlePhoneNumberChange = () => (event) => {
    const value = event.target.value;
    if (value.length != 14) {
      this.setState({
        recipientPhone: value,
      });
    } else {
      this.setState({
        recipientPhoneError: false,
        recipientPhone: value,
      });
    }
  };

  couponDiscountApplied = (discountAmount, appliedCoupon) => {
    // console.log("Updated Discount Admount",discountAmount,appliedCoupon);
    this.setState({
      discountAmount: discountAmount,
      appliedCoupon: appliedCoupon,
    });
  };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    this.changeLanguage();
    this.setThemeColor();

    const { amountError, errorMessage } = this.state;
    const isError = errorMessage !== "";

    const labelStyle = {
      color: isError ? "red" : "black", // Change label color based on error
      fontWeight: "400",
      fontSize: "18px",
    };

    return (
      <>
        {this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>
              {this.props.restaurantDetails?.businessName} | Gift Easy
            </title>
          </Helmet>
        )}
        {!this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>Gift Easy</title>
          </Helmet>
        )}
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={
              this.props.restaurantDetails.backgroundImage
                ? styles.restaurantBackgroundImage
                : styles.backgroudImage
            }
            style={{
              backgroundImage: this.props.restaurantDetails.backgroundImage
                ? "url(" + this.props.restaurantDetails.backgroundImage + ")"
                : "url(" + background3 + ")",
            }}
          >
            <Grid container justify="center" className={styles.mainSection}>
              <Grid item xs={12} className={styles.topSection}>
                {this.state.loading && (
                  <Grid item xs={12}>
                    <div style={{ marginBottom: 35 }}>
                      <Loading />
                    </div>
                  </Grid>
                )}
                {!this.state.loading && (
                  <Grid container justify="center" alignItems="center">
                    {this.props.restaurantDetails?.logo && (
                      <Grid item xs={12}>
                        <div
                          style={{ textAlign: "center" }}
                          onClick={() => this.goToHome()}
                        >
                          <img
                            className={styles.logo}
                            src={this.props.restaurantDetails?.logo}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {this.props.restaurantDetails?.logoHasBusinessName !==
                        "1" && (
                        <h1 style={{ textAlign: "center" }}>
                          {this.props.restaurantDetails?.businessName}
                        </h1>
                      )}
                      <span style={{ textAlign: "center" }}>
                        {this.props.restaurantDetails?.businessLocation}
                      </span>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {this.state.step !== 4 && (
                          <h1 style={{ textAlign: "center", marginTop: 25 }}>
                            {this.props.t("Purchase New Gift Card")}
                          </h1>
                        )}
                      </Grid>
                    </Grid>
                    {/* Step 1 for the Entering Information */}
                    {this.state.step == 1 && (
                      <Grid
                        container
                        spacing={2}
                        className={styles.cardContainer}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Recipient Name")}
                              value={this.state.recipientName}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              onChange={(event) => {
                                if (!/^[a-zA-Z\s]+$/.test(event.target.value)) {
                                  this.setState({
                                    recipientName: event.target.value,
                                  });
                                } else {
                                  this.setState({
                                    recipientName: event.target.value,
                                    recipientNameError: false,
                                  });
                                }
                              }}
                              error={this.state.recipientNameError}
                              helperText={
                                this.state.recipientNameError
                                  ? this.props.t("Please enter valid Name!")
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Recipient Email")}
                              value={this.state.recipientEmail}
                              variant="outlined"
                              onChange={(event) => {
                                if (
                                  !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
                                    event.target.value
                                  )
                                ) {
                                  this.setState({
                                    recipientEmail: event.target.value.trim(),
                                  });
                                } else {
                                  this.setState({
                                    recipientEmail: event.target.value.trim(),
                                    recipientEmailError: false,
                                  });
                                }
                              }}
                              error={this.state.recipientEmailError}
                              helperText={
                                this.state.recipientEmailError
                                  ? this.props.t(
                                      "Please enter valid Email Address!"
                                    )
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t(
                                "Recipient Phone Number (Optional)"
                              )}
                              variant="outlined"
                              error={this.state.recipientPhoneError}
                              helperText={
                                this.state.recipientPhoneError
                                  ? this.props.t(
                                      "Please enter valid phone number!"
                                    )
                                  : ""
                              }
                              InputProps={{
                                inputComponent: PhoneNumberMasking,
                                value: this.state.recipientPhone,
                                onChange: this.handlePhoneNumberChange(),
                              }}
                              labelWidth={60}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Your Message (Optional)")}
                              value={this.state.message}
                              variant="outlined"
                              // onChange={(event) =>
                              //   this.setState({
                              //     message: event.target.value,
                              //   })
                              // }
                              onChange={(event) => {
                                if (
                                  !/^([a-zA-Z0-9 _,.!\(\)\'\";:-]+)$/.test(
                                    this.state.message
                                  )
                                ) {
                                  this.setState({
                                    message: event.target.value,
                                  });
                                } else {
                                  this.setState({
                                    message: event.target.value,
                                    messageError: false,
                                  });
                                }
                              }}
                              error={this.state.messageError}
                              helperText={
                                this.state.messageError
                                  ? this.props.t("Please enter valid message!")
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Your Name")}
                              value={this.state.name}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              onChange={(event) => {
                                if (!/^[a-zA-Z\s]+$/.test(event.target.value)) {
                                  this.setState({
                                    name: event.target.value,
                                  });
                                } else {
                                  this.setState({
                                    name: event.target.value,
                                    nameError: false,
                                  });
                                }
                              }}
                              error={this.state.nameError}
                              helperText={
                                this.state.nameError
                                  ? this.props.t("Please enter valid Name!")
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={styles.formControl}
                            fullWidth={true}
                            variant="outlined"
                          >
                            <TextField
                              label={this.props.t("Your Email")}
                              value={this.state.senderEmail}
                              variant="outlined"
                              onChange={(event) => {
                                if (
                                  !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
                                    event.target.value
                                  )
                                ) {
                                  this.setState({
                                    senderEmail: event.target.value.trim(),
                                  });
                                } else {
                                  this.setState({
                                    senderEmail: event.target.value.trim(),
                                    senderEmailError: false,
                                  });
                                }
                              }}
                              error={this.state.senderEmailError}
                              helperText={
                                this.state.senderEmailError
                                  ? this.props.t(
                                      "Please enter valid Email Address!"
                                    )
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <ReCAPTCHA
                                ref={this.recaptchaRef}
                                size="invisible"
                                // sitekey="6LfgDboZAAAAAGDRLJGarirHauoNOhV4ZAtz8y07"
                                sitekey="6LdFwPIdAAAAALk9YvXTenGDimX2iLOTCyinwhLS"
                                onChange={this.onChange}
                              />
                              {this.state.captchaError && (
                                <div style={{ color: "red" }}>
                                  Please select captcha!
                                </div>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => this.goToAmountStep()}
                                style={{ width: 200 }}
                                size="large"
                                classes={{ sizeLarge: styles.largeButton }}
                              >
                                {this.props.t("Next")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* Step 2 for Entering Amount */}
                    {this.state.step == 2 && (
                      <>
                        {this.props.restaurantDetails.businessName ===
                        "Do My Laundry" ? (
                          <Grid
                            container
                            spacing={2}
                            className={styles.cardContainer}
                            direction="row"
                            justify="center"
                          >
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={styles.formControl}
                                fullWidth={true}
                                variant="outlined"
                              >
                                <InputLabel
                                  htmlFor="outlined-adornment-amount"
                                  error={amountError}
                                  style={labelStyle}
                                >
                                  {this.props.t("Amount")}
                                </InputLabel>
                                <OutlinedInput
                                  label={this.props.t("Enter Amount")}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "23px",
                                  }}
                                  id="outlined-adornment-amount"
                                  value={this.state.amount}
                                  variant="outlined"
                                  onChange={this.handleAmountChange}
                                  inputProps={{ maxLength: 5 }}
                                  // onChange={(event) =>
                                  //   this.setState({
                                  //     amount: event.target.value,
                                  //   })
                                  // }
                                  error={isError}
                                  // helperText={
                                  //   this.state.amountError
                                  //     ? this.props.t(
                                  //         "Please enter valid Amount!"
                                  //       )
                                  //     : ""
                                  // }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon />
                                    </InputAdornment>
                                  }
                                  labelWidth={60}
                                />
                                {isError && ( // Show error message if there is an error
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {errorMessage}
                                  </span>
                                )}
                              </FormControl>
                              <div
                                style={{ textAlign: "center", marginTop: 20 }}
                              >
                                <ButtonGroup
                                  color="primary"
                                  aria-label="outlined primary button group"
                                >
                                  <Button
                                    variant={
                                      this.state.amount == 200
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 200
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 200;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $200
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 300
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 300
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 300;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $300
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 500
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 500
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 500;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $500
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 1000
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 1000
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 1000;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $1000
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            className={styles.cardContainer}
                            direction="row"
                            justify="center"
                          >
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={styles.formControl}
                                fullWidth={true}
                                variant="outlined"
                              >
                                <InputLabel
                                  htmlFor="outlined-adornment-amount"
                                  error={amountError}
                                  style={labelStyle}
                                >
                                  {this.props.t("Amount")}
                                </InputLabel>
                                <OutlinedInput
                                  label={this.props.t("Enter Amount")}
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "23px",
                                  }}
                                  id="outlined-adornment-amount"
                                  value={this.state.amount}
                                  inputProps={{ maxLength: 5 }}
                                  variant="outlined"
                                  // onChange={(event) =>
                                  //   this.setState({
                                  //     amount: event.target.value,
                                  //   })
                                  // }
                                  onChange={this.handleAmountChange}
                                  error={isError}
                                  // helperText={
                                  //   this.state.amountError
                                  //     ? this.props.t(
                                  //         "Please enter a valid amount less than or equal to 500!"
                                  //       )
                                  //     : ""
                                  // }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon />
                                    </InputAdornment>
                                  }
                                  labelWidth={60}
                                />
                                {isError && ( // Show error message if there is an error
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {errorMessage}
                                  </span>
                                )}
                                {/* {this.state.errorMessage && (
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    {this.state.errorMessage}
                                  </span>
                                )} */}
                              </FormControl>
                              <div
                                style={{ textAlign: "center", marginTop: 20 }}
                              >
                                <ButtonGroup
                                  color="primary"
                                  aria-label="outlined primary button group"
                                >
                                  <Button
                                    variant={
                                      this.state.amount == 25 ? "contained" : ""
                                    }
                                    color={
                                      this.state.amount == 25 ? "secondary" : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 25;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $25
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 50 ? "contained" : ""
                                    }
                                    color={
                                      this.state.amount == 50 ? "secondary" : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 50;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $50
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 100
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 100
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 100;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $100
                                  </Button>
                                  <Button
                                    variant={
                                      this.state.amount == 250
                                        ? "contained"
                                        : ""
                                    }
                                    color={
                                      this.state.amount == 250
                                        ? "secondary"
                                        : ""
                                    }
                                    onClick={() => {
                                      const balance_store_limit =
                                        this.props.restaurantDetails
                                          .balanceStoreLimit / 100;
                                      const is_store_limit =
                                        this.props.restaurantDetails
                                          .isStoreLimitEnable;
                                      const maxAmountPercard =
                                        this.props.restaurantDetails
                                          .perCardAmount / 100;
                                      const formattedAmountLimit =
                                        balance_store_limit < 0
                                          ? "$ 0"
                                          : `$${balance_store_limit}`;
                                      const formattedPerCardAmount = `$${maxAmountPercard}`;
                                      const selectedAmount = 250;
                                      if (
                                        is_store_limit &&
                                        selectedAmount > balance_store_limit
                                      ) {
                                        this.setState({
                                          errorMessage: `The remaining balance in the store is only ${formattedAmountLimit} for the day `,
                                        });
                                      } else if (
                                        is_store_limit &&
                                        selectedAmount > maxAmountPercard
                                      ) {
                                        this.setState({
                                          errorMessage: `The maximum amount percard ${formattedPerCardAmount} only!!`,
                                        });
                                      } else {
                                        this.setState({
                                          amount: selectedAmount,
                                          errorMessage: "",
                                        });
                                      }
                                    }}
                                  >
                                    $250
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={6} sm={3}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              fullWidth={true}
                              style={{ marginBottom: 20 }}
                              onClick={() => this.goToPurchaseStep()}
                              disabled={!!this.state.errorMessage}
                            >
                              {this.props.t("Continue")}
                            </Button>
                            <Button
                              fullWidth={true}
                              onClick={() =>
                                this.setState({ step: this.state.step - 1 })
                              }
                            >
                              {this.props.t("Back")}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {this.state.step == 3 && (
                      <>
                        <Card
                          classes={{ root: styles.allergyWarningStyle }}
                          elevation={0}
                        >
                          <CardContent>
                            <Grid container justify="center" alignItems="top">
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <div className={styles.basicInfo}>
                                      {this.props.t("Gift Card Value")}
                                    </div>
                                    <div className={styles.basicInfo}>
                                      <strong>
                                        $ {(+this.state.amount).toFixed(2)}
                                      </strong>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className={styles.basicInfo}>
                                      {this.props.t("Recipient Name")}
                                    </div>
                                    <div className={styles.basicInfo}>
                                      <strong>
                                        {this.state.recipientName}
                                      </strong>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className={styles.basicInfo}>
                                      {this.props.t("Recipient Email")}
                                    </div>
                                    <div className={styles.basicInfo}>
                                      <strong>
                                        {this.state.recipientEmail}
                                      </strong>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justify="center"
                              alignItems="top"
                              className={styles.senderInfo}
                            >
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {this.state.discountAmount && (
                                    <Grid item xs={4}>
                                      <div className={styles.basicInfo}>
                                        {this.props.t("Amount to be Paid")}
                                      </div>
                                      <div className={styles.basicInfo}>
                                        <strong>
                                          $ {this.state.discountAmount}
                                        </strong>
                                      </div>
                                    </Grid>
                                  )}
                                  {this.state.name && (
                                    <Grid item xs={4}>
                                      <div className={styles.basicInfo}>
                                        {this.props.t("Sender Name")}
                                      </div>
                                      <div className={styles.basicInfo}>
                                        <strong>{this.state.name}</strong>
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs={4}>
                                    <div className={styles.basicInfo}>
                                      {this.props.t("Sender Email")}
                                    </div>
                                    <div className={styles.basicInfo}>
                                      <strong>{this.state.senderEmail}</strong>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        {this.props.restaurantDetails.paymentGateway ==
                          "converge" && (
                          <CreditCard
                            isReload={false}
                            amount={(+this.state.amount).toFixed(2)}
                            recipientEmail={this.state.recipientEmail}
                            onPurchase={(request) => this.purchase(request)}
                            onBack={() =>
                              this.setState({
                                step: 2,
                                discountAmount: "",
                                appliedCoupon: "",
                              })
                            }
                            onCouponApplied={(discountAmount, appliedCoupon) =>
                              this.couponDiscountApplied(
                                discountAmount,
                                appliedCoupon
                              )
                            }
                          />
                        )}
                        {this.props.restaurantDetails.paymentGateway ==
                          "clover" && (
                          <CloverPayment
                            isReload={false}
                            amount={(+this.state.amount).toFixed(2)}
                            recipientEmail={this.state.recipientEmail}
                            onPurchase={(token, couponCode) =>
                              this.purhcaseWithClover(token, couponCode)
                            }
                            onCouponApplied={(discountAmount, appliedCoupon) =>
                              this.couponDiscountApplied(
                                discountAmount,
                                appliedCoupon
                              )
                            }
                            onBack={() =>
                              this.setState({
                                step: 2,
                                discountAmount: "",
                                appliedCoupon: "",
                              })
                            }
                          />
                        )}
                        {this.props.restaurantDetails.paymentGateway ==
                          "poynt" && (
                          <PoyntPayment
                            isReload={false}
                            amount={(+this.state.amount).toFixed(2)}
                            recipientEmail={this.state.recipientEmail}
                            onPurchase={(token) =>
                              this.purchaseWithPoynt(token)
                            }
                            onCouponApplied={(discountAmount, appliedCoupon) =>
                              this.couponDiscountApplied(
                                discountAmount,
                                appliedCoupon
                              )
                            }
                            processing={this.state.poyntProcessing}
                            onBack={() =>
                              this.setState({
                                step: 2,
                                discountAmount: "",
                                appliedCoupon: "",
                              })
                            }
                          />
                        )}
                      </>
                    )}
                    {this.state.step == 4 && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className={styles.cardContainer}
                        >
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: "center" }}>
                              {this.props.t(
                                "Gift Card has been sent to the recipient email. If you have any questions, please feel free to reach us at"
                              )}{" "}
                              <a
                                className={classes.link}
                                href={`tel: ${this.props.restaurantDetails?.businessPhone}`}
                              >
                                {this.props.restaurantDetails?.businessPhone}
                              </a>
                            </h3>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => this.goToHome()}
                              style={{ width: 300 }}
                              size="large"
                            >
                              {this.props.t("Purchase New Gift Card")}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        marginBottom: 15,
                        fontSize: 14,
                      }}
                    >
                      <div className="webView">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessName} |{" "}
                        </span>
                        {this.props.restaurantDetails.businessAddress},{" "}
                        {this.props.restaurantDetails.businessCity},{" "}
                        {this.props.restaurantDetails.businessProvince} ,{" "}
                        {this.props.restaurantDetails.businessPostalCode} |{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessPhone}
                        </span>
                      </div>
                      <div className="mobileView">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessName}
                        </span>
                        <br />
                        {this.props.restaurantDetails.businessAddress},{" "}
                        {this.props.restaurantDetails.businessCity},{" "}
                        {this.props.restaurantDetails.businessProvince} ,{" "}
                        {this.props.restaurantDetails.businessPostalCode} <br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.props.restaurantDetails.businessPhone}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item xs={6} sm={4} md={2}>
                          <Button
                            style={{
                              marginLeft: "auto",
                              height: "100%",
                            }}
                            onClick={(event) =>
                              this.changeLanguage(
                                i18n.language == "en" ? "fr" : "en"
                              )
                            }
                            color="inherit"
                          >
                            {this.state.languageHover ? (
                              <div className={styles.languageHover}>Change</div>
                            ) : i18n.language == "en" ? (
                              "French"
                            ) : (
                              "English"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    withTheme(withStyles(classes)(withTranslation()(PurchaseCard)))
  )
);
