import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  setRestaurantDetails: ["businessName", "locationName"],
  setRestaurantDetailsSuccess: ["info"],
  setRestaurantDetailsError: {},
  changeLanguage: ["language"],
});

export const RestaurantStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_RESTAURANT_STATE = Immutable({
  businessName: null,
  businessLocation: null,
  businessId: null,
  locationId: null,
  logo: null,
  logoHasBusinessName: null,
  backgroundImage: null,
  loading: true,
  businessDomain: null,
  locationDomain: null,
  restaurantMessage: null,
  themeColor: "#B51441",
  businessAddress: null,
  businessCity: null,
  businessProvince: null,
  businessPostalCode: null,
  businessPhone: null,
  apiAccessKey: null,
  paymentGateway: null,
  pcApiKey: null,
  deviceId: null,
  businessCode: null,
  locationCode: null,
  preferredLanguage: "en",
  currentLanguage: false,
  isStoreLimitEnable: null,
  balanceCardLimit: null,
  perCardAmount: null,
  balanceStoreLimit: null
});

// Selectors to derive data from state
export const RestaurantSelectors = {
  getRestaurantDetails: (state) => {
    console.log('business info state', state)
    return {
      businessName: state.restaurant.businessName,
      businessLocation: state.restaurant.businessLocation,
      businessId: state.restaurant.businessId,
      locationId: state.restaurant.locationId,
      logo: state.restaurant.logo,
      logoHasBusinessName: state.restaurant.logoHasBusinessName,
      backgroundImage: state.restaurant.backgroundImage,
      loading: state.restaurant.loading,
      businessDomain: state.restaurant.businessDomain,
      locationDomain: state.restaurant.locationDomain,
      restaurantMessage: state.restaurant.restaurantMessage,
      themeColor: state.restaurant.themeColor,
      businessAddress: state.restaurant.businessAddress,
      businessCity: state.restaurant.businessCity,
      businessProvince: state.restaurant.businessProvince,
      businessPostalCode: state.restaurant.businessPostalCode,
      businessPhone: state.restaurant.businessPhone,
      apiAccessKey: state.restaurant.apiAccessKey,
      paymentGateway: state.restaurant.paymentGateway,
      deviceId: state.restaurant.deviceId,
      pcApiKey: state.restaurant.pcApiKey,
      businessCode: state.restaurant.businessCode,
      locationCode: state.restaurant.locationCode,
      preferredLanguage: state.restaurant.preferredLanguage,
      currentLanguage: state.restaurant.currentLanguage,
      isStoreLimitEnable: state.restaurant.isStoreLimitEnable,
      balanceCardLimit: state.restaurant.balanceCardLimit,
      perCardAmount: state.restaurant.perCardAmount,
      balanceStoreLimit: state.restaurant.balanceStoreLimit
    };
  },
};

// Reducers

export const setRestaurantDetails = (state, { businessName, locationName }) => {
  return {
    loading: true,
    businessName: null,
    businessLocation: null,
    businessId: null,
    locationId: null,
    logo: null,
    logoHasBusinessName: null,
    backgroundImage: null,
    businessDomain: businessName,
    locationDomain: locationName,
    restaurantMessage: null,
    themeColor: "#B51441",
    businessAddress: null,
    businessCity: null,
    paymentGateway: null,
    businessProvince: null,
    businessPostalCode: null,
    businessPhone: null,
    apiAccessKey: null,
    businessCode: null,
    locationCode: null,
    preferredLanguage: "en",
    currentLanguage: false,
    isStoreLimitEnable: null,
    balanceCardLimit: null,
    perCardAmount: null,
    balanceStoreLimit: null
  };
};

export const setRestaurantDetailsSuccess = (state, { info }) => {
  console.log("Info", info);
  return {
    loading: false,
    businessName: info.name,
    businessLocation: info.business_location,
    businessId: info.slug,
    locationId: info.location.slug,
    logo: info.logo_url,
    logoHasBusinessName: info.is_logo_with_name,
    backgroundImage: info.setting.background_image_url,
    businessDomain: info.slug,
    locationDomain: info.location.slug,
    restaurantMessage: null,
    themeColor: info.setting.theme_color,
    paymentGateway: info.default_payment_mode,
    businessAddress: info.location.address,
    businessCity: info.location.city,
    businessProvince: info.location.state,
    businessPostalCode: info.location.postal_code,
    businessPhone: info.location.contact.phone_number,
    businessCode: info.code,
    locationCode: info.location.code,
    apiAccessKey: info.clover.api_access_key, // :TODO
    pcApiKey: null, // TODO
    deviceId: null, //TODO
    preferredLanguage: info.setting.preferred_language,
    currentLanguage: false,
    isStoreLimitEnable : info.is_store_limit,
    balanceCardLimit: info.perday_card_limit,
    perCardAmount: info.perday_percard_transaction_limit,
    balanceStoreLimit:info.perday_store_transaction_limit
  };
};

export const setRestaurantDetailsError = (state, { info }) => {
  return {
    loading: false,
    businessName: null,
    businessLocation: null,
    businessId: null,
    locationId: null,
    logo: null,
    logoHasBusinessName: null,
    backgroundImage: null,
    businessDomain: null,
    locationDomain: null,
    paymentGateway: null,
    restaurantMessage: null,
    themeColor: "#B51441",
    businessAddress: null,
    businessCity: null,
    businessProvince: null,
    businessPostalCode: null,
    businessPhone: null,
    apiAccessKey: null,
    businessCode: null,
    locationCode: null,
    preferredLanguage: "en",
    currentLanguage: false,
    isStoreLimitEnable: null,
    balanceCardLimit: null,
    perCardAmount: null,
    balanceStoreLimit: null
  };
};

export const changeLanguage = (state, { language }) => {
  return {
    loading: state.loading,
    businessName: state.businessName,
    businessLocation: state.businessLocation,
    businessId: state.businessId,
    locationId: state.locationId,
    logo: state.logo,
    logoHasBusinessName: state.logoHasBusinessName,
    backgroundImage: state.backgroundImage,
    businessDomain: state.businessDomain,
    locationDomain: state.locationDomain,
    restaurantMessage: state.restaurantMessage,
    themeColor: state.themeColor,
    paymentGateway: state.paymentGateway,
    businessAddress: state.businessAddress,
    businessCity: state.businessCity,
    businessProvince: state.businessProvince,
    businessPostalCode: state.businessPostalCode,
    businessPhone: state.businessPhone,
    businessCode: state.businessCode,
    locationCode: state.locationCode,
    apiAccessKey: state.apiAccessKey, // :TODO
    pcApiKey: state.pcApiKey, // TODO
    deviceId: state.deviceId, //TODO
    preferredLanguage: state.preferredLanguage,
    currentLanguage: language,
    isStoreLimitEnable: state.isStoreLimitEnable,
    balanceCardLimit: state.balanceCardLimit,
    perCardAmount : state.perCardAmount,
    balanceStoreLimit: state.balanceStoreLimit
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_RESTAURANT_STATE, {
  [Types.SET_RESTAURANT_DETAILS]: setRestaurantDetails,
  [Types.SET_RESTAURANT_DETAILS_SUCCESS]: setRestaurantDetailsSuccess,
  [Types.SET_RESTAURANT_DETAILS_ERROR]: setRestaurantDetailsError,
  [Types.CHANGE_LANGUAGE]: changeLanguage,
});
