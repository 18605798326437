import apisauce from "apisauce";
import { api_key } from "./apiKey";
import { baseUrl } from "./baseUrl";

const create = (baseURL = baseUrl) => {
  const restaurantApi = apisauce.create({
    baseURL,
  });

  const setRestaurantDetails = (businessName, locationName) => {
    return restaurantApi.get(
      "business/" + businessName + "/location/" + locationName
    );
  };

  return {
    setRestaurantDetails,
  };
};

export default {
  create,
};
