import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
import configureStore from "./CreateStore";
import rootSaga from "../Sagas/";
import { connectRouter } from "connected-react-router";

export const history = createBrowserHistory();

/* ------------- Assemble The Reducers ------------- */
export const reducers = (history) =>
  combineReducers({
    restaurant: require("./RestaurantRedux").reducer,
    router: connectRouter(history),
  });

export default () => {
  let finalReducers = reducers(history);

  let { store, sagasManager, sagaMiddleware, persistor } = configureStore(
    finalReducers,
    rootSaga,
    history
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("./").reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require("../Sagas").default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }

  return { store, persistor };
};
