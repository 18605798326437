import React, { Component } from "react";
import "./App.scss";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InitialPlaceholderScreen from "./Screens/InitialPlaceholderScreen";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
// import favicon from "./assets/images/favicon.ico";
// import Favicon from "react-favicon";

toast.configure();

class App extends Component {
  constructor(props) {
    super(props);

    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: "#B51441",
          contrastText: "#ffffff",
        },
        primary: {
          main: "rgb(179, 177, 177)",
        },
      },
    });

    this.state = {
      theme: theme,
    };
  }

  changeThemeColor = (color) => {
    console.log("Changing color", color);
    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: color,
          contrastText: "#ffffff",
        },
        primary: {
          main: "rgb(179, 177, 177)",
        },
      },
    });
    this.setState({
      theme: theme,
    });
  };

  render() {
    return (
      <Router>
        {/* <Favicon url={favicon} /> */}
        <ThemeProvider theme={this.state.theme}>
          <ConnectedRouter history={this.props.history}>
            <Switch>
              <Route
                path="/:restaurant/:location"
                render={() => (
                  <InitialPlaceholderScreen
                    onThemeColorChange={this.changeThemeColor}
                  />
                )}
              />
            </Switch>
            <div className="footer-text">
              Copyrights &copy; {new Date().getFullYear()} Gift Easy
            </div>
          </ConnectedRouter>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
