import apisauce from "apisauce";
import { api_key } from "./apiKey";
import { baseUrl } from "./baseUrl";

const create = (baseURL = baseUrl) => {
  const giftCardsApi = apisauce.create({
    baseURL,
  });

  const purchaseGiftCard = (businessName, locationName, requestObj) => {
    return giftCardsApi.post(
      "/gift-card/purchase?business=" +
        businessName +
        "&location=" +
        locationName,
      JSON.stringify(requestObj)
    );
  };

  const checkGiftCard = (businessName, locationName, requestObj) => {
    return giftCardsApi.post(
      "/gift-card/check-balance?business=" +
        businessName +
        "&location=" +
        locationName,
      JSON.stringify(requestObj)
    );
  };

  const validateCoupon = (
    businessName,
    locationName,
    couponCode,
    requestObj
  ) => {
    return giftCardsApi.post(
      "coupon/" +
        couponCode +
        "/validate?business=" +
        businessName +
        "&location=" +
        locationName,
      JSON.stringify(requestObj)
    );
  };

  const reloadGiftCard = (businessName, locationName, requestObj) => {
    return giftCardsApi.post(
      "/gift-card/reload?business=" +
        businessName +
        "&location=" +
        locationName,
      JSON.stringify(requestObj)
    );
  };

  const purchaseGiftCardPoynt = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "addnewgiftcardpoynt.php",
      JSON.stringify(requestObj)
    );
  };

  const reloadGiftCardPoynt = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post(
      "rechargegiftcardpoynt.php",
      JSON.stringify(requestObj)
    );
  };

  const couponValidate = (requestObj) => {
    requestObj.api_key = api_key;
    return giftCardsApi.post("validate_coupon.php", JSON.stringify(requestObj));
  };

  return {
    purchaseGiftCard,
    checkGiftCard,
    reloadGiftCard,
    purchaseGiftCardPoynt,
    reloadGiftCardPoynt,
    couponValidate,
    validateCoupon,
  };
};

export default {
  create,
};
