import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CurrentCard from "../../components/CurrentCard/CurrentCard";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";

class ReloadCard extends Component {
  setThemeColor = () => {
    if (
      this.props.restaurantDetails.themeColor &&
      this.props.restaurantDetails.themeColor !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(this.props.restaurantDetails.themeColor);
    }
  };

  changeLanguage = (lan) => {
    if (lan == "en") {
      moment.locale("fr");
      this.props.i18n.changeLanguage("fr");
    } else {
      moment.locale("en");
      this.props.i18n.changeLanguage("en");
    }
  };

  render() {
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        moment.locale("fr");
        this.props.i18n.changeLanguage("fr");
      }
    }
    this.setThemeColor();
    return (
      <>
        <CurrentCard type="reload" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withTranslation()(ReloadCard)))
);
