import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import HomePage from "./HomePage/HomePage";
import PurchaseCard from "./PurchaseCard/PurchaseCard";
import ReloadCard from "./ReloadCard/ReloadCard";
import CheckBalance from "./CheckBalance/CheckBalance";

class InitialPlaceholderScreen extends Component {
  constructor(props) {
    super(props);
  }

  onThemeColorChange = (color) => {
    console.log("Changing color with color Initial", color);
    this.props.onThemeColorChange(color);
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={this.props.match.path}
          render={() => (
            <HomePage onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/purchase-card`}
          render={() => (
            <PurchaseCard onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/reload-card`}
          render={() => (
            <ReloadCard onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/check-balance`}
          render={() => (
            <CheckBalance onThemeColorChange={this.onThemeColorChange} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(InitialPlaceholderScreen);
